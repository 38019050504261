import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useCallback, useEffect, useRef, useState } from 'react';

export type UploadedFile = {
  url: string;
  id: string;
};

export type FileUploadHandler = (files: UploadedFile[]) => void;

export type InitParams = {
  contextId?: string;
  apiBaseUrl: string;
  allowedTypes?: string[];
  onFilesUploaded: FileUploadHandler;
};

export const useUploadService = ({
  apiBaseUrl,
  allowedTypes,
  contextId: initContextId,
  onFilesUploaded,
}: InitParams) => {
  const connection = useRef<HubConnection>();

  const [contextId, setContextId] = useState(initContextId);
  const [qrCode, setQrCode] = useState<string>();

  const stop = useCallback(async () => {
    if (connection.current) {
      await connection.current.stop();
      connection.current = undefined;
    }
  }, []);

  // init context/qr-code
  useEffect(() => {
    let isMounted = true;

    const init = async () => {
      const response = await fetch(`${apiBaseUrl}/v1/Upload/CreateContext`, {
        method: 'post',
        body: JSON.stringify({
          id: contextId,
          allowedTypes,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const body = await response.json();
      if (!isMounted) {
        return;
      }
      setContextId(body.id);
      setQrCode(body.qrCode);
    };

    init();

    return () => {
      isMounted = false;
    };
  }, [allowedTypes, apiBaseUrl, contextId]);

  // init signalr
  useEffect(() => {
    if (!contextId) {
      return;
    }

    let isMounted = true;

    const init = async () => {
      const _connection = new HubConnectionBuilder()
        .withUrl(`${apiBaseUrl}/hubs/Upload?contextId=${contextId}`)
        .withAutomaticReconnect()
        .build();

      _connection.on('FilesUploaded', async function (contextId: string, fileIds: string[]) {
        const result = fileIds.map<UploadedFile>((fileId) => ({
          url: `${apiBaseUrl}/v1/Upload?contextId=${contextId}&fileId=${fileId}`,
          id: fileId,
        }));
        onFilesUploaded(result);
      });

      await _connection.start();

      if (isMounted) {
        connection.current = _connection;
      } else {
        _connection.stop();
      }
    };

    init();

    return () => {
      isMounted = false;
      stop();
    };
  }, [apiBaseUrl, contextId, onFilesUploaded, stop]);

  return {
    contextId,
    qrCode,
  };
};
